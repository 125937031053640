import ru from "./messages.ru_ru.json";
import en from "./messages.en_us.json";
import uk from "./messages.uk_ua.json";

const TRANSLATIONS = {
  ru_ru: ru,
  en_us: en,
  uk_ua: uk
};

export { TRANSLATIONS };

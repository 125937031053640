import { util } from "styled-system";

const grid = {
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  breakpoints: ["768px", "1024px", "1280px"]
};

// TODO 0 || 1 ??
const mq = [null, ...grid.breakpoints.map(util.mq)];

const font = {
  fontSizes: [12, 13, 14, 16, 18, 20, 22, 24, 28, 32, 34, 40, 44, 56],
  lineHeights: [
    1,
    "16px",
    "20px",
    "24px",
    "32px",
    "34px",
    "40px",
    "44px",
    "48px",
    "56px"
  ],
  fontWeights: [300, 400, 500, 700],
  letterSpacings: [0, "0.01em", "0.02em", "0.05em"]
};

const other = {
  shadows: [`0 0 0 1px #BAB9D3`]
};

export default {
  ...grid,
  mq,
  ...font,
  ...other
};

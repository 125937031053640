import React, { Fragment } from "react";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "emotion-theming";
import { IntlProvider, addLocaleData } from "react-intl";
import PropTypes from "prop-types";

import theme from "@common/theme";

import ruLocaleData from "react-intl/locale-data/ru";
import uaLocaleData from "react-intl/locale-data/uk";
import enLocaleData from "react-intl/locale-data/en";

import { TRANSLATIONS } from "./function/i18n";

import { ApolloClient } from "apollo-client";
import { rootContext } from "@common/types/rootContext";

addLocaleData(uaLocaleData);
addLocaleData(ruLocaleData);
addLocaleData(enLocaleData);

import Component from "./function";

const Root = ({
  apolloClient,
  mainEntity,
  locale,
  currencyCodeISO,
  fragmentEntity,
  ...props
}) => {
  const formats = {
    number: {
      currency: {
        style: "currency",
        currency: currencyCodeISO
      }
    }
  };
  const localeMap = {
    en_us: "en",
    ru_ru: "ru",
    uk_ua: "uk"
  };
  locale = locale && locale.toLowerCase();
  const intlLocale = locale && localeMap[locale];
  return (
    <Fragment>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider {...{ theme }}>
          <IntlProvider
            locale={intlLocale}
            messages={TRANSLATIONS[locale]}
            formats={formats}
            defaultFormats={formats}
          >
            <Component
              fragmentEntity={fragmentEntity}
              rootContext={mainEntity}
            />
          </IntlProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>
  );
};

Root.propTypes = {
  // Клиент для запросов к api
  apolloClient: PropTypes.instanceOf(ApolloClient),
  // Объект главной сущности блока (Товар, Категория...)
  mainEntity: rootContext,
  // Локаль клиента
  locale: PropTypes.string.isRequired,
  // Код валюты на сайте
  currencyCodeISO: PropTypes.string.isRequired
};

export default Root;

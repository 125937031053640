import React from 'react';
import styled from 'react-emotion';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import StyledIcon from '@common/components/StyledIcon';
import { Container, Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import { Button } from '@common/components/Button';
import Link from '@common/components/Link';
import { Inline } from '../Inline';
import { Position } from '../Position';
import { Arrow, Cross, NethouseLogo } from '../assets/icons';

const Top = styled('div')`
  background: rgba(2, 80, 139, 0.95);
  width: 100%;
`;

const TopContainer = styled('div')`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const Bottom = styled('div')`
  background: rgba(5, 142, 217, 1);
`;

const PROPS = {
  onClickCrossButton: PropTypes.func
};

const IsNotPaid = ({ onClickCrossButton }) => {
  return (
    <Container>
      <Row justifyContent="center">
        <Position fixed left={0} bottom="0" width="100%" zIndex="11">
          <Top>
            <Link
              href="https://nethouse.ru/?p=advert"
              target="_blank"
              display="flex"
            >
              <TopContainer>
                <Row justifyContent="center" width="100%" mgx={0}>
                  <Column
                    display="inline-block"
                    textAlign="right"
                    width={['0px', '23%', '15%']}
                    px={['0px', 4, 1]}
                  >
                    <StyledIcon
                      pt={1}
                      pl={3}
                      color="#fff"
                      height="40px"
                      width={[0, '124px', '148px']}
                    >
                      <NethouseLogo />
                    </StyledIcon>
                  </Column>

                  <Column width={['100%', '77%', '85%']}>
                    <Inline
                      display="inline-block"
                      width={['100%', '64%', '65%']}
                      verticalAlign="middle"
                    >
                      <Column py={['13px', 1, 0]}>
                        <Text
                          height="auto"
                          fontSize={['12px', '16px', '17px']}
                          color="#fff"
                          textAlign="center"
                          py={['0px', '20px', '27px']}
                          px={['10px', null, null]}
                        >
                          <FormattedMessage
                            width="100%"
                            id="notifyLine.text.doYoWantCreateSite"
                            defaultMessage="Сайт создан на платформе Nethouse. Хотите такой же?"
                          />
                        </Text>
                      </Column>
                    </Inline>

                    <Inline
                      display="inline-block"
                      width={['100%', '35%', '35%']}
                      minWidth={['200px', null, null]}
                      textAlign={['center', 'left', 'center']}
                      verticalAlign="middle"
                    >
                      <Column justifyContent="center" pl="0px" py={['10px', 1, 0]}>
                        <Button
                          py={['14px', '10px', '13px']}
                          px={[4, 3, 4]}
                          bg="#0CCA4A"
                          uppercase
                          color="#fff"
                          fontSize={[1]}
                        >
                          <Text
                            py={0}
                            display="inline-block"
                            fontSize={['12px', '10px', '12px']}
                            color="#fff"
                            textAlign="center"
                          >
                            <FormattedMessage
                              id="notifyLine.text.createSite"
                              defaultMessage="Создать сайт"
                            />
                          </Text>
                          <Text
                            py={0}
                            display={['inline-block', 'none', 'inline-block']}
                            fontSize={['12px', '11px', '12px']}
                            color="#fff"
                            textAlign="center"
                          >
                            &nbsp;
                            <FormattedMessage
                              id="notifyLine.text.free"
                              defaultMessage="бесплатно"
                            />
                          </Text>
                        </Button>
                      </Column>
                    </Inline>
                  </Column>
                </Row>
              </TopContainer>
            </Link>
            <Position
              absolute
              right={['10px', '18px', '20px']}
              top={['10px', '24px', '27px']}
              zIndex="12"
            >
              <Button
                color="rgb(5, 142, 217)"
                lineHeight={40}
                width="24px"
                height="24px"
                onClick={() => onClickCrossButton()}
              >
                <Cross />
              </Button>
            </Position>
          </Top>
          <Bottom>
            <Inline display={['none', 'block', 'block']}>
              <Row justifyContent="center">
                <a
                  href="https://nethouse.ru/tarifend?utm_source=nethouse&utm_medium=user_sites&utm_campaign=adv_line&utm_content=instruction"
                  target="_blank"
                >
                  <Column>
                    <Text
                      color="#fff"
                      textAlign="center"
                      lineHeight={['28px']}
                      fontSize={['14px']}
                    >
                      <FormattedMessage
                        id={'notifyLine.text.forOwner'}
                        defaultMessage={'Владельцу сайта'}
                      />
                      <Button pl={1} pt={'5px'} width="20px" height="20px">
                        <Arrow />
                      </Button>
                    </Text>
                  </Column>
                </a>
              </Row>
            </Inline>
          </Bottom>
        </Position>
      </Row>
    </Container>
  );
};

IsNotPaid.propTypes = PROPS;

export { IsNotPaid };

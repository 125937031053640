import PropTypes from "prop-types";

export const rootContext = PropTypes.shape({
  // Идентифиактор блока в конструкторе
  blockId: PropTypes.string.isRequired,
  // Номер страницы в случае постраничной навигации
  page: PropTypes.number,
  // Идентификатор главной сущности блока
  id: PropTypes.string,
  // Мод в котором находится пользователь для отображения управления
  mode: PropTypes.oneOf(["public", "editor", "preview"]),
  // Дополнительная мета информация для блока
  // Используется редактором 1.0 для рендера блоков реакта.
  metadata: PropTypes.shape({
    // Основноый Флаг через который спрашиваем, брать ли информацию из метадаты или нет.
    use: PropTypes.bool,
    // Флаг выводить блок скрытым или нет.
    isVisible: PropTypes.bool,
    // Флаг выводить заголовок или нет
    titleIsVisible: PropTypes.bool,
    // Идендификатор сетки используется для получения стилей
    grid: PropTypes.string,
    // Идендификатор заказа
    orderId: PropTypes.string,
    // Токен
    token: PropTypes.string,
    editor: PropTypes.object
  })
});

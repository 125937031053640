import React from 'react';

import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import styled from 'react-emotion';

import { top, right, left, bottom, zIndex, width } from 'styled-system';

const Position = styled('div')`
    ${top};
    ${right};
    ${left};
    ${bottom};
    ${zIndex};
    ${width};
    ${ifProp('relative', 'position: relative;')}
    ${ifProp('absolute', 'position: absolute;')}
    ${ifProp('fixed', 'position: fixed;')}
`;

Position.displayName = 'Position';

Position.propTypes = {
  relative: PropTypes.bool,
  absolute: PropTypes.bool,
  fixed: PropTypes.bool
};

export default Position;

import React from 'react';

import { withProp } from 'styled-tools';

import styled from 'react-emotion';

import { display, width, minWidth, textAlign } from 'styled-system';

const Inline = styled('div')`
  ${display};
  ${width};
  ${minWidth};
  ${textAlign};
  ${withProp('verticalAlign', align => `vertical-align: ${align}`)};
`;

export { Inline };

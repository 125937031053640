import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { NotifyLine } from './NotifyLine';

const GET_SUBSCRIPTIONS = gql`
  query getSubscriptions {
    site {
      activeTariff {
        typeOf
      }
    }
  }
`;

export default compose(
  graphql(GET_SUBSCRIPTIONS, {
    options: () => {
      return {
        errorPolicy: 'ignore'
      };
    },
    props: ({ data }) => ({ ...data })
  })
)(NotifyLine);

import styled from "react-emotion";
import PropTypes from "prop-types";
import tag from "clean-tag";
import {
  display,
  space,
  fontSize,
  color,
  fontFamily,
  textAlign,
  hover,
  active,
  focus
} from "styled-system";
import { lineHeight, fontWeight, letterSpacing } from "../../utils";
import { ifProp } from "styled-tools";

tag.defaultProps.blacklist = [
  ...tag.defaultProps.blacklist,
  "uppercase",
  "transition"
];

// TODO добавить настройку text-transform
export const Link = styled(tag.a)`
  outline: none;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
  text-decoration: none;

  ${display}
  ${space}
  ${fontSize}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${color}
  ${fontFamily}
  ${textAlign}
  ${hover}
  ${active}
  ${focus}
  ${ifProp("uppercase", "text-transform: uppercase;")}
  ${ifProp("transition", "transition: all ease-out 0.1s;")}
`;

Link.propTypes = {
  ...display.propTypes,
  ...space.propTypes,
  ...fontSize.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...hover.propTypes,
  ...active.propTypes,
  ...focus.propTypes,
  uppercase: PropTypes.bool,
  transition: PropTypes.bool
};

Link.displayName = "Link";

export default Link;

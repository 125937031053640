import { responsiveStyle } from "styled-system";

export const lineHeight = responsiveStyle({
  prop: "lineHeight",
  alias: "lh",
  key: "lineHeights"
});

export const letterSpacing = responsiveStyle({
  prop: "letterSpacing",
  key: "letterSpacings"
});

export const fontWeight = responsiveStyle({
  prop: "fontWeight",
  alias: "fw",
  key: "fontWeights"
});

export const borderRadius = responsiveStyle({
  prop: "borderRadius",
  alias: "br",
  key: "borderRadii"
});

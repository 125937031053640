import React from "react";
import styled from "react-emotion";
import { Flex, Box } from "grid-emotion";
import { textAlign } from "styled-system";

const Container = styled(Box)`
  max-width: 1152px;
`;

Container.defaultProps = {
  mx: "auto",
  px: [3, 4, 0]
};

// Container.Block - это Container для пользовательских блоков
const ContainerBlock = styled(Box)`
  max-width: 1152px;
`;

ContainerBlock.defaultProps = {
  mx: "auto",
  px: [3, 4, 2]
};

Container.Block = ContainerBlock;

const RowBase = props => <Flex mx={[-1, null, -2]} {...props} />;
const ColumnBase = props => <Box px={[1, null, 2]} {...props} />;

const Column = styled(ColumnBase)`
  ${textAlign};
`;

Column.displayName = "Column";

const Row = styled(RowBase)`
  ${textAlign};
`;

Row.displayName = "Row";

export { Row, Column, Container };

import React, { Component } from 'react';

import { IsNotPaid } from './Template/IsNotPaid';
import { TariffFirst } from './Template/TariffFirst';
import PropTypes from 'prop-types';

const TYPE_TARIFF_FIRST = 44;

const PROPS = {
  site: PropTypes.shape({
    activeTariff: PropTypes.shape({
      typeOf: PropTypes.number
    })
  })
};

class NotifyLine extends Component {
  constructor(props) {
    super(props);
    this.hideBanner = this.hideBanner.bind(this);
    this.state = {
      isHidden: false
    };
  }

  hideBanner() {
    this.setState({ isHidden: true });
  }

  render() {
    if (this.state.isHidden) {
      return null;
    }

    const tariff = this.props.site.activeTariff;
    if (tariff === null) {
      return <IsNotPaid onClickCrossButton={this.hideBanner} />;
    }

    if (tariff.typeOf === TYPE_TARIFF_FIRST) {
      return <TariffFirst onClickCrossButton={this.hideBanner} />;
    }

    return null;
  }
}

NotifyLine.propTypes = PROPS;

export { NotifyLine };

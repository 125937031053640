import React from "react";
import styled from "react-emotion";
import {
  space,
  width,
  maxWidth,
  height,
  maxHeight,
  display,
  color
} from "styled-system";
import tag from "clean-tag";
import { mapProps } from "recompose";

const SVGWrapper = styled(tag.i)`
  & > svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  ${display}
  ${space}
  ${width}
  ${height}
  ${maxWidth}
  ${maxHeight}
  ${color}
`;

SVGWrapper.defaultProps = {
  display: "inline-block",
  width: 32,
  height: 32
};

const Icon = ({ children, ...props }) => {
  return <SVGWrapper {...props}>{children}</SVGWrapper>;
};

Icon.propTypes = {
  ...display.propTypes,
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...maxWidth.propTypes,
  ...maxHeight.propTypes,
  ...color.propTypes
};

export default mapProps(({ height, maxHeight, width, maxWidth, ...props }) => {
  const mH = maxHeight ? maxHeight : height;
  const mW = maxWidth ? maxWidth : width;

  return {
    ...props,
    maxHeight: mH,
    maxWidth: mW,
    height,
    width
  };
})(Icon);

import styled, { css } from "react-emotion";
import PropTypes from "prop-types";
import tag from "clean-tag";
import {
  display,
  space,
  fontSize,
  color,
  fontFamily,
  textAlign,
  position
} from "styled-system";
import { lineHeight, fontWeight, letterSpacing } from "../../utils";
import { ifProp } from "styled-tools";

tag.defaultProps.blacklist = [...tag.defaultProps.blacklist, "linethrough"];

// TODO добавить настройку text-transform
export const Text = styled(tag.div)`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;

  ${display}
  ${position}
  ${space}
  ${fontSize}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${color}
  ${fontFamily}
  ${textAlign}
  ${ifProp(
    "linethrough",
    css`
      text-decoration: line-through;
    `
  )}
`;

Text.propTypes = {
  ...display.propTypes,
  ...space.propTypes,
  ...fontSize.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  linethrough: PropTypes.bool
};

Text.defaultProps = {
  color: "#13293D"
};

Text.displayName = "Text";

export default Text;

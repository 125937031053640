import PropTypes from "prop-types";
import styled from "react-emotion";
import {
  display,
  width,
  space,
  fontFamily,
  fontSize,
  color,
  textAlign,
  boxShadow,
  hover,
  focus,
  disabled
} from "styled-system";
import {
  lineHeight,
  fontWeight,
  letterSpacing,
  borderRadius
} from "../../utils";
import { ifProp } from "styled-tools";
import tag from "clean-tag";

tag.defaultProps.blacklist = [...tag.defaultProps.blacklist, "uppercase"];

const Button = styled(tag.button)`
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
  transition: all ease-out 0.1s;

  svg {
    vertical-align: top;
  }

  ${display}
  ${width}
  ${space}
  ${fontFamily}
  ${fontSize}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${color}
  ${textAlign}
  ${boxShadow}
  ${borderRadius}
  ${hover}
  ${focus}
  ${disabled}
  ${ifProp("uppercase", "text-transform: uppercase;")}
`;

Button.defaultProps = {
  type: "button"
};

Button.displayName = "Button";
Button.propTypes = {
  ...display.propTypes,
  ...width.propTypes,
  ...space.propTypes,
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...boxShadow.propTypes,
  ...borderRadius.propTypes,
  ...hover.propTypes,
  ...focus.propTypes,
  uppercase: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"])
};

export default Button;
